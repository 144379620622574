<template>
	<v-card
		class="my-2 mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column"
		max-width="1200"
		ripple
		style="width: 100%"
		:to="{ name: 'Event', params: { id: event.id } }"
		rounded="xl"
	>
		<v-img class="mt-2 mx-auto py-auto flex-grow-1" contain width="180" :src="event.imgURL" />
		<v-card-title>
			<span class="text-h6 font-weight-bold" style="word-break: normal">{{ event.title }}</span>
		</v-card-title>
		<v-card-subtitle>
			<span class="text-subtitle-2" style="word-break: normal">
				<template v-if="event.companyName">
					<i18n path="offers.managedBy" tag="span">
						<template #name>
							<span style="color: #FF6F00">{{ event.companyName }}</span>
						</template>
						<template #parent>
							<span>{{ event.company.name }}</span>
						</template>
					</i18n>
				</template>
				<template v-else>
					<span style="color: #FF6F00">{{ event.company.name }}</span>
				</template>
			</span>
		</v-card-subtitle>
		<v-card-text class="d-flex flex-column justify-space-between flex-grow-1">
			<div>
				<p>
					<span style="color: orange; word-break: normal">{{ event.type.name }}</span> <v-icon>mdi-map-marker</v-icon> {{ event.info.location }}
				</p>
				<div class="d-flex justify-space-between">
					<span><v-icon>mdi-calendar</v-icon> {{ humanTime(event.info.timestamp.start) }}</span>
					<i18n path="events.leftCapacity" tag="span" class="text-body-1">
						<template #number>
							<span style="color: #FF6F00">{{ leftCapacityNumber }}</span>
						</template>
					</i18n>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'EventSearchCard',
	props: {
		event: {
			type: Object,
			required: true
		}
	},
	computed: {
		leftCapacityNumber() {
			if (this.event.type.code == 'interview') {
				let remaining = this.event.attendances.spotsLeft || 0
				return remaining > 10 ? Math.floor(remaining / 5) * 5 : remaining
			} else {
				let remaining = this.event.attendances.maxCapacity - this.event.attendances.numTotal || 0
				return remaining > 10 ? Math.floor(remaining / 5) * 5 : remaining
			}
		}
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss')
		}
	}
}
</script>
